// AB test configuration
export const SOURCE_FILTER_TEST = {
  name: 'AiListingsEnabledProd',
  variations: ['original', 'ai_listings_enabled'],
};

// Session storage key for AB test assignment
const AB_TEST_KEY = 'ABTestAssignment';
const INITIALIZATION_KEY = 'ABTestInitialized';

const logABTestEvent = (event, data = {}) => {
  const timestamp = new Date().toISOString();
  if (data && Object.keys(data).length > 0) {
    console.log(`[AB Test ${timestamp}] ${event}`, data);
  } else {
    console.log(`[AB Test ${timestamp}] ${event}`);
  }
};

const isAlreadyInitialized = () => {
  // Check if we're in a browser environment
  if (typeof window === 'undefined') return false;

  try {
    return window?.sessionStorage?.getItem(INITIALIZATION_KEY) === 'true';
  } catch (e) {
    return false;
  }
};

const markAsInitialized = () => {
  // Check if we're in a browser environment
  if (typeof window === 'undefined') return;

  try {
    window?.sessionStorage?.setItem(INITIALIZATION_KEY, 'true');
    logABTestEvent(
      'Marked as initialized - will continue with AB test initialization but not start another one'
    );
  } catch (e) {
    console.error('Failed to mark AB test as initialized:', e);
  }
};

// Store these functions on window so they're available to the stringified getABtestingConfig
if (typeof window !== 'undefined') {
  window.abTestHelpers = {
    logABTestEvent,
    isAlreadyInitialized,
    markAsInitialized,
    SOURCE_FILTER_TEST,
    // Add setExperimentVersion to the helpers
    setExperimentVersion: version => {
      if (!window?.sessionStorage) return;

      try {
        // Check if a version is already assigned
        const existingData = window.sessionStorage.getItem(AB_TEST_KEY);
        if (existingData) {
          const { version: existingVersion } = JSON.parse(existingData);
          logABTestEvent('Skipping version assignment - already assigned', {
            existingVersion,
            attemptedVersion: version,
          });
          return;
        }

        const data = {
          version,
          storedAt: new Date().toISOString(),
        };
        window.sessionStorage.setItem(AB_TEST_KEY, JSON.stringify(data));
        logABTestEvent('Version assigned', { version });
      } catch (e) {
        logABTestEvent('Error storing version', { error: e.message });
      }
    },
    // Add a callback for when Matomo is loaded
    onMatomoLoaded: () => {
      // Initialize AB testing when Matomo is ready
      if (typeof window._paq === 'undefined') {
        logABTestEvent('Skipping Matomo AB test initialization - _paq not available');
        return;
      }

      if (isAlreadyInitialized()) {
        const currentVersion = getExperimentVersion();
        logABTestEvent('Skipping Matomo AB test initialization - already initialized', {
          version: currentVersion,
        });
        return;
      }

      const timeout = setTimeout(() => {
        console.log('AB Testing initialization timed out');
      }, 2000);

      try {
        window._paq.push([
          'AbTesting::create',
          getABtestingConfig(timeout, () => {
            clearTimeout(timeout);
          }),
        ]);
      } catch (e) {
        console.error('Failed to initialize AB testing:', e);
        clearTimeout(timeout);
      }
    },
  };
}

export const getExperimentVersion = () => {
  // Check if we're in a browser environment
  const isBrowser = typeof window !== 'undefined';

  if (isBrowser && window?.sessionStorage) {
    const storedData = window.sessionStorage.getItem(AB_TEST_KEY);
    if (!storedData) {
      logABTestEvent('No stored version found, using original');
      return SOURCE_FILTER_TEST.variations[0];
    }

    try {
      const { version, storedAt } = JSON.parse(storedData);
      // Check if stored data is fresh (less than 1 day old)
      const oneDayAgo = new Date();
      oneDayAgo.setDate(oneDayAgo.getDate() - 1);

      if (new Date(storedAt) > oneDayAgo) {
        logABTestEvent('Retrieved version', { version });
        return version;
      }
      logABTestEvent('Stored version expired, using original');
      return SOURCE_FILTER_TEST.variations[0];
    } catch (e) {
      logABTestEvent('Error parsing AB test data', { error: e.message });
      return SOURCE_FILTER_TEST.variations[0];
    }
  }
  // logABTestEvent('SessionStorage not available, using original');
  return SOURCE_FILTER_TEST.variations[0];
};

export const setExperimentVersion = version => {
  // Check if we're in a browser environment
  if (typeof window === 'undefined' || !window.abTestHelpers) return;

  window.abTestHelpers.setExperimentVersion(version);
};

export const getABtestingConfig = (timeout, resolve) => {
  // Check if we're in a browser environment
  if (typeof window === 'undefined' || !window.abTestHelpers) {
    if (typeof resolve === 'function') {
      resolve(SOURCE_FILTER_TEST.variations[0]);
      if (timeout) clearTimeout(timeout);
    }
    return null;
  }

  // Use the globally available helpers
  const {
    logABTestEvent,
    setExperimentVersion,
    SOURCE_FILTER_TEST,
    isAlreadyInitialized,
    markAsInitialized,
  } = window.abTestHelpers;

  // Prevent multiple initializations
  if (isAlreadyInitialized()) {
    logABTestEvent('Skipping initialization - already initialized');
    if (typeof resolve === 'function') {
      resolve(getExperimentVersion());
      if (timeout) clearTimeout(timeout);
    }
    return null;
  }

  // Mark as initialized immediately to prevent double initialization
  markAsInitialized();
  logABTestEvent('Initializing AB test');

  // Default resolve function if none provided
  const safeResolve = typeof resolve === 'function' ? resolve : () => {};

  return {
    name: SOURCE_FILTER_TEST.name,
    percentage: 100,
    includedTargets: [
      {
        attribute: 'url',
        inverted: '0',
        type: 'contains',
        value: 'https://gerryweber.brandback.de/',
      },
    ],
    excludedTargets: [],
    startDateTime: '2025/03/04 13:30:00 UTC',
    variations: [
      {
        name: SOURCE_FILTER_TEST.variations[0],
        activate: () => {
          if (timeout) clearTimeout(timeout);
          setExperimentVersion(SOURCE_FILTER_TEST.variations[0]);
          safeResolve(SOURCE_FILTER_TEST.variations[0]);
          logABTestEvent('Original variant activated');
        },
      },
      {
        name: SOURCE_FILTER_TEST.variations[1],
        activate: () => {
          if (timeout) clearTimeout(timeout);
          setExperimentVersion(SOURCE_FILTER_TEST.variations[1]);
          safeResolve(SOURCE_FILTER_TEST.variations[1]);
          logABTestEvent('AI listings variant activated');
        },
      },
    ],
    trigger: () => {
      logABTestEvent('AB test triggered');
      return true;
    },
  };
};
