import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, IconClose } from '../../components';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';

import css from './InfoBanner.module.css';

const InfoBanner = props => {
  const { infoText, intl } = props;
  const [isVisible, setIsVisible] = useState(false);
  const bannerClosedKey = 'infoBannerClosed';

  useEffect(() => {
    try {
      // Check if the banner has been closed before
      const isBannerClosed = localStorage.getItem(bannerClosedKey) === 'true';
      setIsVisible(!isBannerClosed);
    } catch (error) {
      // Fallback to hiding the banner if localStorage fails
      setIsVisible(false);
      console.error('Failed to access localStorage:', error);
    }
  }, []);

  const handleClose = () => {
    try {
      // Save to localStorage that the banner has been closed
      localStorage.setItem(bannerClosedKey, 'true');
    } catch (error) {
      console.error('Failed to save to localStorage:', error);
    }
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  const closeModalMessage = intl.formatMessage({ id: 'Modal.closeModal' });

  return (
    <div className={css.container}>
      <div className={css.placeholder}></div>
      <div className={css.textContainer}>{infoText}</div>
      <Button onClick={handleClose} rootClassName={css.closeButton} title={closeModalMessage}>
        <span className={css.closeText}>
          <FormattedMessage id="Modal.close" />
        </span>
        <IconClose rootClassName={css.closeIcon} />
      </Button>
    </div>
  );
};

InfoBanner.defaultProps = {
  infoText: '',
};

InfoBanner.propTypes = {
  infoText: PropTypes.string,
  intl: intlShape.isRequired,
};

export default injectIntl(InfoBanner);
